// project import
import other from './other';
import pages from './pages';
import samplePage from './sample-page';
import products from './products';
import admin from './admin';

// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [samplePage, pages, products, other, admin]
};

export default menuItems;
