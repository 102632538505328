import {
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    DialogTitle,
    Button,
    TextField,
    FormHelperText,
    Grid,
} from "@mui/material";
import { useState, useEffect } from "react";
import { strengthIndicator, strengthColor } from "utils/password-strength";

interface NewPasswordModalProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: (newPassword: string, givenName: string, familyName: string) => void;
}

const NewPasswordModal: React.FC<NewPasswordModalProps> = ({
    open,
    handleClose,
    handleConfirm,
}) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [givenName, setGivenName] = useState("");
    const [familyName, setFamilyName] = useState("");
    const [passwordStrength, setPasswordStrength] = useState(0);

    const handleGivenNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGivenName(event.target.value);
    };

    const handleFamilyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFamilyName(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    };

    useEffect(() => {
        const strengths = strengthIndicator(password);
        setPasswordStrength(strengths);
    }, [password]);

    const isPasswordMatch = password === confirmPassword;
    const passwordStrengthColor = strengthColor(passwordStrength);

    const handleConfirmClick = () => {
        if (passwordStrength >= 4 && isPasswordMatch) {
            handleConfirm(password, givenName, familyName);
            handleClose();
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle style={{ textAlign: 'center' }}>
                <Typography variant="h2" color="primary">
                    {"Willkommen bei Aunom!"}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Bitte nennen Sie uns Ihren Namen:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            autoFocus
                            variant="outlined"
                            id="givenName"
                            label="Vorname"
                            type="text"
                            fullWidth
                            value={givenName}
                            onChange={handleGivenNameChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            id="familyName"
                            label="Nachname"
                            type="text"
                            fullWidth
                            value={familyName}
                            onChange={handleFamilyNameChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Bitte legen Sie ein neues Passwort fest:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={!isPasswordMatch || passwordStrength < 4}
                            variant="outlined"
                            margin="normal"
                            id="password"
                            label="New Password"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        {password && (
                            <FormHelperText>
                                Stärke: {passwordStrengthColor?.label}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={!isPasswordMatch}
                            variant="outlined"
                            margin="normal"
                            id="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            fullWidth
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                        {!isPasswordMatch && password && (
                            <FormHelperText>Passwords do not match</FormHelperText>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button variant="contained" color="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleConfirmClick} style={{ marginLeft: '20px' }}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>

    );
};

export default NewPasswordModal;