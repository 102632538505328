import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// product routing
const AppChat = Loadable(lazy(() => import('views/products/chat')));
const AppBilder = Loadable(lazy(() => import('views/products/bilder')));

// admin routing
const AppFirmen = Loadable(lazy(() => import('views/admin/firmen')));
const AppBenutzer = Loadable(lazy(() => import('views/admin/benutzer')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: '/sample-page',
            element: <SamplePage />
        },
        {
            path: '/products/chat',
            element: <AppChat />
        },
        {
            path: '/products/bilder',
            element: <AppBilder />
        },
        {
            path: '/admin/firmen',
            element: <AppFirmen />
        },
        {
            path: '/admin/benutzer',
            element: <AppBenutzer />
        }
    ]
};

export default MainRoutes;
