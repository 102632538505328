// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';

// API
import axiosServices from 'utils/axios';
import { AxiosResponse } from 'axios';
import mockAxios from 'utils/mockAxios';

// types
import { DefaultRootStateProps } from 'types';
import { Nachricht } from 'types/chat';

// ----------------------------------------------------------------------

//using correct axios
let axiosInstance: typeof axiosServices | typeof mockAxios;

if (process.env.REACT_APP_STAGE === 'local') {
    axiosInstance = mockAxios;
} else {
    axiosInstance = axiosServices;
}

interface ResponseData {
    nachricht_id: number;
    unterhaltung_id: number;
  }

const initialState: DefaultRootStateProps['chat'] = {
    error: null,
    nachrichten: [],
    unterhaltung: {},
    unterhaltungen: []
};

const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USER
        getUserSuccess(state, action) {
            state.unterhaltung = action.payload;
        },

        // GET UNTERHALTUNG NACHRICHTEN
        getUnterhaltungNachrichtenSuccess(state, action) {
            state.nachrichten = action.payload.data;
        },

        // GET UNTERHALTUNG NACHRICHT
        getUnterhaltungNachrichtSuccess(state, action) {
            console.log(action.payload);
            state.nachrichten[state.nachrichten.length - 1] = action.payload.data[0];
        },

        // INSERT CHAT SUCCESS
        insertChatSuccess(state, action) {
            const newNachricht: Nachricht = action.payload;
            state.nachrichten.push(newNachricht);
        },

        // UPDATE CHAT SUCCESS
        updateChatSuccess(state, action) {
            const newNachricht: Nachricht = {
                nachricht_id: action.payload.data.nachricht_id, 
                nachricht_ist_von_ai: true,
                bewertung: 0,
                nachricht: '',
                zeitstempel: '0'
            }
            state.nachrichten.push(newNachricht);
        },

        // GET USERS
        getUnterhaltungenSuccess(state, action) {
            state.unterhaltungen = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUser(id: number) {
    return async () => {
        try {
            const response = await axiosInstance.post('/api/chat/users/id', { id });
            dispatch(slice.actions.getUserSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUnterhaltungNachrichten(unterhaltung_id: number) {
    return async () => {
        try {
            const response = await axiosInstance.get(`/get_avatar_unterhaltung_nachrichten?unterhaltung_id=${unterhaltung_id}`);
            dispatch(slice.actions.getUnterhaltungNachrichtenSuccess(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUnterhaltungNachricht(unterhaltung_id: number, nachricht_id: number) {
    return async () => {
        try {
            const res = await axiosInstance.get(`/get_avatar_unterhaltung_nachricht?unterhaltung_id=${unterhaltung_id}&nachricht_id=${nachricht_id}`);
            if (res?.status === 200) {
                console.log('MESSAGE FROM AI!');
                dispatch(slice.actions.getUnterhaltungNachrichtSuccess(res));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function insertChat(chat: Nachricht) {
    return async () => {
        try {
            dispatch(slice.actions.insertChatSuccess(chat));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}



export function updateChat(chat: Nachricht, unterhaltung_id: number) {
    return async () => {
        try {
            const postObject = {
                    ...chat,
                    unterhaltung_id: unterhaltung_id
            }
            const responseData = await axiosInstance.post<ResponseData>('/post_avatar_unterhaltung_nachricht', postObject) as AxiosResponse<ResponseData>;
            dispatch(slice.actions.updateChatSuccess(responseData));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUnterhaltungen(ki_avatar_id: number) {
    return async () => {
        try {
            const response = await axiosInstance.get(`/get_avatar_unterhaltungen?ki_avatar_id=${ki_avatar_id}`);
            dispatch(slice.actions.getUnterhaltungenSuccess(response?.data.unterhaltungen));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
