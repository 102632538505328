// assets
import { OverrideIcon } from 'types';
import {
    IconApps,
    IconBuilding,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconPhoto,
    IconBrandChrome,
    IconSitemap,
    IconHelp,
    IconKey,
    IconUsers
} from '@tabler/icons';

const icons: { [key: string] : OverrideIcon } = {
    IconApps,
    IconBuilding,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconPhoto,
    IconBrandChrome,
    IconSitemap,
    IconHelp,
    IconKey,
    IconUsers
};

export const defaultIcon = icons.IconApps;

export function getTablerIcon(iconName: string): OverrideIcon {
    const IconComponent = icons[iconName];

    if (!IconComponent) {
        console.log(`Icon "${iconName}" not found. Using default icon.`);
        return defaultIcon;
    }
    return IconComponent;
}

