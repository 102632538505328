// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import chatReducer from './slices/chat';
import bildReducer from './slices/bild';
import firmenReducer from './slices/firmen';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    menu: menuReducer,
    chat: chatReducer,
    bild: bildReducer,
    firmen: firmenReducer,
});

export default reducer;
