// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';

// API
import axiosServices from 'utils/axios';
import mockAxios from 'utils/mockAxios';


// types
import { DefaultRootStateProps } from 'types';

// using correct axios
let axiosInstance: typeof axiosServices | typeof mockAxios;

if (process.env.REACT_APP_STAGE === 'local') {
    axiosInstance = mockAxios;
} else {
    axiosInstance = axiosServices;
}

interface FirmaData {
    firmaName: string;
    openaiKey : string;
}

interface BenutzerData {
    email: string;
    name: string;
    firmaId: string;
}

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['firma'] = {
    error: null,
    benutzer: [],
    firmen: []
};

const slice = createSlice({
    name: 'firmen',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET FIRMEN
        getFirmenSuccess(state, action) {
            state.firmen = action.payload;
        },

        // GET BENUTZER
        getBenutzerSuccess(state, action) {
            state.benutzer = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFirmen() {
    return async () => {
        try {
            const response = await axiosInstance.get('/admin/get_firmen');
            dispatch(slice.actions.getFirmenSuccess(response.data.firmen));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getBenutzer() {
    return async () => {
        try {
            const response = await axiosInstance.get('/admin/get_benutzer');
            dispatch(slice.actions.getBenutzerSuccess(response.data.benutzer));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function putBenutzer(data: BenutzerData) {
    return async () => {
        try {
            await axiosInstance.put('/admin/put_benutzer', data);
            dispatch(getBenutzer()); // It fires getBenutzer to retrieve all new "Benutzer"
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function putFirma(data: FirmaData) {
    return async () => {
        try {
            await axiosInstance.put('/admin/put_firma', data);
            dispatch(getFirmen()); // It fires getFirmen to retrieve all new "Firma"
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
