import axios from 'axios';
import { getSessionCookie } from 'utils/authHelper';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3010/' });

//Interceptors to add Token
axiosServices.interceptors.request.use(
    async (config) => {
        try {
            const token = await getSessionCookie();
            config.headers = config.headers ? config.headers : {};
            config.headers.Authorization = token ? `Bearer ${token}` : '';
        } catch (e) {
            console.log('error in getting token', e);
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
