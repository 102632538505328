import { NavItemType } from 'types';

// ==============================|| ADMIN MENU ITEMS ||============================== //

const admin: NavItemType = {
    id: 'admin',
    title: 'Admin',
    icon: 'IconApps',
    type: 'group',
    children: [
        {
            id: 'firmen',
            title: 'Firmen',
            type: 'item',
            icon: 'IconBuilding',
            url: '/admin/firmen',
            breadcrumbs: false
        },
        {
            id: 'benutzer',
            title: 'Benutzer',
            type: 'item',
            icon: 'IconUsers',
            url: '/admin/benutzer',
            breadcrumbs: false
        }
    ]
};

export default admin;
