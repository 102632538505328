import React, { createContext, useEffect, useState, useRef, useReducer } from 'react';
import { CognitoUser, CognitoUserPool, CognitoUserSession, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';
import Loader from 'ui-component/Loader';
import NewPasswordModal from 'ui-component/modals/NewPasswordModal';
import { AWSCognitoContextType, InitialLoginContextProps } from 'types/auth';

const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

export const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_AWS_POOL_ID || '',
    ClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID || ''
});

const setSession = (serviceToken?: string | null) => {
    serviceToken? localStorage.setItem('serviceToken', serviceToken) : localStorage.removeItem('serviceToken');
};

const AWSCognitoContext = createContext<AWSCognitoContextType | null>(null);

export const AWSCognitoProvider = ({ children }: { children: React.ReactElement }) => {
    const authenticatedUserRef = useRef<CognitoUser | null>(null);
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = () => {
            try {
                const user = userPool.getCurrentUser();
                if (user) {
                    user.getSession((err: any, session: any) => {
                        if (err) {
                            console.error(err);
                            dispatch({ type: LOGOUT });
                        } else if (session && session.isValid()) {
                            setSessionAndDispatchUser(user, session.getAccessToken().getJwtToken());
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({type: LOGOUT});
            }
        };
    
        init();
    }, []);

    const setSessionAndDispatchUser = async (user: CognitoUser, serviceToken: string) => {
        setSession(serviceToken);
    
        await user.getUserAttributes((err, attributes) => {
            if (err) {
                console.log(err);
                dispatch({type: LOGOUT});
                return;
            } 
            const emailAttr = attributes?.find(attribute => attribute.getName() === 'email');
            const given_nameAttr = attributes?.find(attribute => attribute.getName() === 'given_name');
            const family_nameAttr = attributes?.find(attribute => attribute.getName() === 'family_name');
    
            const email = emailAttr ? emailAttr.getValue() : '';
            const name = (given_nameAttr ? given_nameAttr.getValue() : '') + ' ' +
                         (family_nameAttr ? family_nameAttr.getValue() : '');
    
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: {
                        email: email,
                        name: name
                    }
                }
            });
        });
    };

    // set 'NewPassword' modal open state
    const [isNewPasswordOpen, setNewPasswordIsOpen] = useState(false);

    const login = async (email: string, password: string) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });

        const authData = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        usr.authenticateUser(authData, {
            onSuccess: (session: CognitoUserSession) => {
                setSessionAndDispatchUser(usr, session.getAccessToken().getJwtToken())
            },
            onFailure: (_err: any) => {},
            newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
                authenticatedUserRef.current = usr;
                setNewPasswordIsOpen(true);
            }
        });
    };

    const handlePasswordUpdate = (newPassword: string, given_name: string, family_name: string) => {
        console.log('HANDLE PASS UPDT');
        const user = authenticatedUserRef.current;
    
        if (user) {
            console.log('USR FND');
            user.completeNewPasswordChallenge(newPassword, {given_name: given_name, family_name: family_name}, {
                onSuccess: (result) => {
                    console.log('SUCC');
                    setSessionAndDispatchUser(user, result.getAccessToken().getJwtToken());
                },
    
                onFailure: (err) => {
                    console.log(err);
                }
            });
        }
    
        setNewPasswordIsOpen(false);
    };
    
    

    const register = (email: string, password: string, firstName: string, lastName: string) =>
        new Promise((success, rej) => {
            userPool.signUp(
                email,
                password,
                [
                    new CognitoUserAttribute({ Name: 'email', Value: email }),
                    new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` })
                ],
                [],
                async (err: any, result: any) => {
                    if (err) {
                        rej(err);
                        return;
                    }
                    success(result);
                }
            );
        });

    const logout = () => {
        const loggedInUser = userPool.getCurrentUser();
        if (loggedInUser) {
            setSession(null);
            loggedInUser.signOut();
            dispatch({ type: LOGOUT });
        }
    };

    const forgotPassword = async (email: string) => {
        const user = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        user.forgotPassword({
            onSuccess: function () {},
            onFailure: function () {}
        });
    };

    const resetPassword = async (verificationCode: string, newPassword: string) => {
        const email = localStorage.getItem('email');
        if (email) {
            const user = new CognitoUser({
                Username: email as string,
                Pool: userPool
            });
            return new Promise((resolve, reject) => {
                user.confirmPassword(verificationCode, newPassword, {
                    onSuccess: function (data: any) {
                        localStorage.removeItem('email');
                        resolve(data);
                    },
                    onFailure: function (error: any) {
                        reject(error.message);
                    }
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                reject('Token expired try again');
            });
        }
    };

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <AWSCognitoContext.Provider value={{ ...state, login, logout, forgotPassword, resetPassword, updateProfile, register }}>
            {children}
            <NewPasswordModal open={isNewPasswordOpen} handleClose={() => setNewPasswordIsOpen(false)} handleConfirm={handlePasswordUpdate} />
        </AWSCognitoContext.Provider>
    );
};

export default AWSCognitoContext;
