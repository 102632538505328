import { useEffect, useState } from 'react';

import Routes from 'routes';
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import Notistack from 'ui-component/third-party/Notistack';
import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
import { useDispatch } from 'store';
import { fetchMenu } from 'store/slices/menu';
import Loader from 'ui-component/Loader';

const App = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchMenu()).then(() => {
            setLoading(false);
        });
    }, [dispatch]);

    if (loading) {
        return <Loader />;
    }

    return (
        <ThemeCustomization>
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <Notistack>
                            <Routes />
                            <Snackbar />
                        </Notistack>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
        </ThemeCustomization>
    );
};

export default App;
