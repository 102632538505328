import { NavItemType } from 'types';

// ==============================|| PRODUCTS MENU ITEMS ||============================== //

const products: NavItemType = {
    id: 'products',
    title: 'products',
    icon: 'IconApps',
    type: 'group',
    children: [
          {
            id: 'chat',
            title: 'chat',
            type: 'item',
            icon: 'IconMessages',
            url: '/products/chat'
        },
        {
            id: 'bilder',
            title: 'bilder',
            type: 'item',
            icon: 'IconPhoto',
            url: '/products/bilder'
        }
    ]
};

export default products;
