import { NavItemType } from 'types';

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other: NavItemType = {
    id: 'sample-docs-roadmap',
    icon: 'IconHelp',
    type: 'group',
    children: [
        {
            id: 'documentation',
            title: 'documentation',
            type: 'item',
            url: 'https://codedthemes.gitbook.io/berry/',
            icon: 'IconHelp',
            external: true,
            target: true
        },
        {
            id: 'roadmap',
            title: 'roadmap',
            type: 'item',
            url: 'https://codedthemes.gitbook.io/berry/roadmap',
            icon: 'IconSitemap',
            external: true,
            target: true
        }
    ]
};

export default other;
