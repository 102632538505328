// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const samplePage: NavItemType = {
    id: 'sample-page',
    title: 'sample-page',
    icon: 'IconBrandChrome',
    type: 'group',
    url: '/sample-page'
};

export default samplePage;
