import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { userPool } from '../contexts/AWSCognitoContext';

export const getSessionCookie = async () => {
    let data: CognitoUserSession | null = null;
    const user = userPool.getCurrentUser();
    if (user) {
        data = await new Promise((resolve, reject) => {
            user.getSession((err: any, session: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(session as CognitoUserSession);
                }
            });
        });
    }
    return data?.isValid() ? data.getIdToken().getJwtToken() : null;
};