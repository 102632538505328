// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';

// API
import { AxiosResponse } from 'axios';
import axiosServices from 'utils/axios';
import mockAxios from 'utils/mockAxios';


// types
import { DefaultRootStateProps } from 'types';

// ----------------------------------------------------------------------

// using correct axios
let axiosInstance: typeof axiosServices | typeof mockAxios;

if (process.env.REACT_APP_STAGE === 'local') {
    axiosInstance = mockAxios;
} else {
    axiosInstance = axiosServices;
}

interface GetImageResponse{
    url : string;
    error? : string;
}


// The initial state of your 'bild' is assumed to contain 'bilderUrls', an array of urls, and an 'error', a string
const initialState: DefaultRootStateProps['bild'] = {
    bilderUrls: [],
    error: '',
    loading: false
};

const slice = createSlice({
    name: 'bild',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // START LOADING
        startLoading(state) {
            state.loading = true;
        },

        // STOP LOADING
        stopLoading(state) {
            state.loading = false;
        },

        // GET POST IMAGE URLS SUCCESS
        getPostImageURLsSuccess(state, action) {
            state.bilderUrls.push(action.payload);
        }

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
// Async action to get the picture URLs

interface BILD_IDs_Response {
    bild_ids: number[];
}

export function getPostImageURLs(prompt: string) {
    return async () => {
        try {
            // Start loading
            dispatch(slice.actions.startLoading());

            // Wait five seconds before starting
            setTimeout(async () => {
                const responseIds = await axiosInstance.post<BILD_IDs_Response>('/post_bilder', { prompt }) as AxiosResponse<BILD_IDs_Response>;
                const data = responseIds.data?.bild_ids;

                const fetchUrlPromises = data.map((id: number) => 
                    // Recursive function to handle retries
                    (async function fetchWithRetry(remainingRetries: number = 3) {
                        try {
                            const responseUrl = await axiosInstance.get(`/get_bild?bild_id=${id}`) as AxiosResponse<GetImageResponse>;
                            // Retry if status is 202
                            if (responseUrl.status === 202) {
                                if (remainingRetries > 0) {
                                    // We now return a new Promise that resolves after a delay.
                                    return new Promise((resolve, reject) => {
                                        setTimeout(() => resolve(fetchWithRetry(remainingRetries - 1)), 5000); // Retry after 5 seconds
                                    });
                                } else {
                                    // Suitable error handling for maximum retries reached can be done here if desired.
                                    throw new Error("Maximum retries reached");
                                }
                            } else if(responseUrl.status == 200){
                                const url = responseUrl.data.url;
                                dispatch(slice.actions.getPostImageURLsSuccess(url)); // Dispatch each image as soon as it's loaded
                            }
                        } catch (err) {
                            throw err;
                        }
                    })()
                );

                // Wait for all images to finish loading
                const settledResults = await Promise.allSettled(fetchUrlPromises);

                // Stop loading
                dispatch(slice.actions.stopLoading());

                // Filter out successful promises and log the rejected ones
                const rejectedPromises = settledResults.filter(p => p.status === 'rejected');
                if (rejectedPromises.length > 0) {
                    console.log('Rejected promises:', rejectedPromises);
                }
            }, 5000);
        } catch (error) {
            // Stop loading
            dispatch(slice.actions.stopLoading());
            // Re-throw the error
            throw error;
        }
    };
}