// types
import { MenuProps } from 'types/menu';
import { NavItemType } from 'types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// API
import axiosServices from 'utils/axios';
import mockAxios from 'utils/mockAxios';

//project imports
import menuItems from 'menu-items';

// using correct axios
let axiosInstance: typeof axiosServices | typeof mockAxios;

if (process.env.REACT_APP_STAGE === 'local') {
    axiosInstance = mockAxios;
} else {
    axiosInstance = axiosServices;
}

// initial state
const initialState: MenuProps = {
    selectedItem: ['dashboard'],
    selectedID: null,
    drawerOpen: false,
    error: null,
    menu: menuItems.items,
    status: 'idle',  // initial status,
    isApiLoaded: false
};

export const fetchMenu = createAsyncThunk('menu/fetchMenu', async (_, { getState, rejectWithValue }) => {
    const state = getState() as { menu: MenuProps };

    // If the API call has already been made, simply return nothing
    if (state.menu.isApiLoaded) {
        return;
    } try {
        const response = await axiosInstance.get('/get_menu');
        const apiMenuItems = response.data.menuItems;

        const mapItems = (items: NavItemType[], parentShow: boolean | undefined = true, parentId: string = ''): NavItemType[] => {
            return items.map((item) => {
                const id = parentId ? `${parentId}.${item.id}` : item.id;
                const apiItem = apiMenuItems.find((api: NavItemType) => api.id === id);
                let show: boolean | undefined = apiItem ? apiItem.show : parentShow;

                if (item.type === 'group') {
                    const children = item.children ? mapItems(item.children, show, id) : [];
                    return { ...item, children, show };
                } else {
                    return { ...item, show };
                }
            });
        };

        return mapItems(menuItems.items);
    } catch (err) {
        if (err instanceof Error) {
            return rejectWithValue(err.message);
        }
        return rejectWithValue('An unknown error occurred.');
    }
});






// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.selectedItem = action.payload;
        },

        activeID(state, action) {
            state.selectedID = action.payload;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenu.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMenu.fulfilled, (state, action) => {
                if (action.payload) {
                    state.status = 'succeeded';
                    state.menu = action.payload;
                    state.isApiLoaded = true;
                }
            })
            .addCase(fetchMenu.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default menu.reducer;
export const { activeItem, activeID, openDrawer } = menu.actions;
